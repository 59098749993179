@import 'src/styles/variables';

.matchList {
  position: relative;
  margin-bottom: 100px;
}

.matchTable {
  max-height: 1000px;
  overflow-y: auto;
  overflow-x: hidden;
}

.winningPlayer {
  font-weight: bold;
}