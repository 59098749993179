@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
body{margin:0;font-family:'Poppins', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background:#f8f6f6}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}

.notifications{position:fixed;right:0;bottom:0;margin-right:20px;margin-bottom:20px;z-index:1501}.notifications div{padding:15px 30px;color:#fff;box-shadow:2px 2px 2px #e2d9d9;opacity:1;animation-name:fadeInOutOpacity;animation-iteration-count:1;animation-timing-function:ease-in-out;animation-duration:8s}@keyframes fadeInOutOpacity{0%{opacity:0}20%{opacity:1}80%{opacity:1}100%{opacity:0}}.notifications .success{background:#67e85d}.notifications .error{background:#f15b5b}.notifications .warning{background:#ffe772}

html{height:100%}body{min-height:100%;position:relative}.header{background:#1c1c1c;height:60px;display:flex;align-items:center;justify-content:space-between;padding:5px 10px}.header img{height:50px}.content{position:relative;padding:30px}.content.navPresent{margin-left:250px}.navBar{display:flex;flex-direction:column;justify-content:space-between;background:#fff;width:250px;position:absolute;top:70px;left:0;height:calc(100% - 70px)}.navBarFooter{font-size:14px;color:#666;text-align:center;padding:30px 10px}h1,h2,h3,h4,h5{margin-top:0}@media only screen and (max-width: 600px){.content{padding:5px}}

.matchList{position:relative;margin-bottom:100px}.matchTable{max-height:1000px;overflow-y:auto;overflow-x:hidden}.winningPlayer{font-weight:bold}

.login{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;padding:20px;width:600px;margin:0 auto}.login .submit{margin-top:20px}.login .signUpLink{font-size:13px;margin-top:5px;font-style:italic}

.setPassword{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;padding:20px;width:600px;margin:0 auto}.setPassword .submit{margin-top:20px}

.statCard{background:#fff;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;border:1px solid #e2d9d9;border-radius:5px;box-shadow:1px 1px 1px #e2d9d9;margin:20px;padding:20px;width:200px;text-align:center;display:flex;flex-direction:column;justify-content:space-between;flex:1 1}.statCard:first-child{margin-left:0}.statCard:last-child{margin-right:0}.statCard .statTitle{flex:1 1;font-size:16px;font-weight:500;white-space:nowrap}.statCard .stat{flex:3 1}

.playerCompare{display:flex;justify-content:space-between}

